import {onMounted, ref, toRefs, watch} from 'vue';
import Button from 'primevue/button';
import Dialog from 'primevue/dialog';
import {useToast} from 'vue-toastification';
import {i18n} from '@/utils/i18n';
import {addNewComment} from '@/services/tickets';
import Editor from '@tinymce/tinymce-vue';
import LoadingPlugin from 'vue-loading-overlay';
import {defaultTicketTemplates, getAll} from '@/services/ticket-templates';

export default {
    emits: ['close-dialog'],
    components: {
        PrimeDialog: Dialog,
        tiny: Editor,
        'p-button': Button,
        loading: LoadingPlugin
    },
    props: {
        ticketDetails: Object,
        commentDefaultValue: String,
        displayDialog: Boolean
    },
    setup(props: any, context: any) {
        const {ticketDetails} = toRefs(props);
        const showDialog = ref(false);
        const comment = ref(null);
        const toast = useToast();
        const savingInProgress = ref(false);
        const ticketTemplates = ref([]);

        onMounted(() => {
            getAll({})
                .then((data) => {
                    ticketTemplates.value = [
                        ...Object.values(defaultTicketTemplates.comment).filter(
                            (cmt: any) => {
                                return !(data.data || []).some(
                                    (dbItem: any) => dbItem.name === cmt.key
                                );
                            }
                        ),
                        ...data.data.map((dbItem: any) => {
                            return {...dbItem, description: ''};
                        })
                    ];
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        });

        watch(props, (args) => {
            showDialog.value = args.displayDialog;
            comment.value = args.commentDefaultValue || '';
        });

        const onCancelClick = () => {
            comment.value = '';
            showDialog.value = false;
            context.emit('close-dialog');
        };

        const onConfirmClicked = async () => {
            try {
                savingInProgress.value = true;
                await addNewComment(
                    ticketDetails.value.ticketNumber,
                    comment.value
                );
                toast.success(
                    i18n.global.t('messages.commentAddedSuccessfully')
                );
                context.emit('close-dialog', true);
            } catch (error: any) {
                toast.error(error.response?.data?.error || error.message);
                context.emit('close-dialog');
            } finally {
                savingInProgress.value = false;
                comment.value = '';
                showDialog.value = false;
            }
        };

        return {
            showDialog,
            comment,
            onCancelClick,
            onConfirmClicked,
            savingInProgress,
            tinyTemplates: ticketTemplates,
            tinyUrl: process.env?.VUE_APP_TINYMCE_URL
        };
    }
};
