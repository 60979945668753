import {ref, watch} from 'vue';
import Dialog from 'primevue/dialog';
import Button from 'primevue/button';
import {useToast} from 'vue-toastification';
import {i18n} from '@/utils/i18n';
import Editor from '@tinymce/tinymce-vue';
import LoadingPlugin from 'vue-loading-overlay';
import useVuelidate from '@vuelidate/core';
import {helpers, required} from '@vuelidate/validators';
import InputText from 'primevue/inputtext';
import Checkbox from 'primevue/checkbox';
import {addTemplate, editTemplate} from '@/services/ticket-templates';

export default {
    emits: ['close-dialog'],
    components: {
        PrimeDialog: Dialog,
        tiny: Editor,
        InputText,
        'p-button': Button,
        'p-checkbox': Checkbox,
        loading: LoadingPlugin
    },
    props: {
        displayDialog: Boolean,
        selectedTemplate: Object
    },
    setup(props: any, context: any) {
        const selectedTemplate = ref(null);
        const showDialog = ref(false);
        const toast = useToast();
        const savingInProgress = ref(false);
        const submitted = ref(false);

        const state = ref({
            subject: null,
            body: null,
            public: false
        });

        const rules = {
            subject: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            body: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            public: {}
        };

        const v$ = useVuelidate(rules, state);

        watch(props, (args) => {
            showDialog.value = args.displayDialog;
            selectedTemplate.value = args.selectedTemplate;
            if (args.selectedTemplate) {
                state.value = Object.assign(state.value, {
                    subject: selectedTemplate.value?.title,
                    body: selectedTemplate.value?.content,
                    public: !!selectedTemplate.value?.public
                });
            }
        });

        const onCancelClick = (event: any) => {
            event.preventDefault();
            showDialog.value = false;
            submitted.value = false;
            state.value = {
                subject: null,
                body: null,
                public: false
            };
            context.emit('close-dialog');
        };

        const handleSubmit = async (isFormValid: boolean) => {
            submitted.value = true;
            if (!isFormValid) {
                return;
            }

            try {
                savingInProgress.value = true;
                selectedTemplate.value
                    ? await editTemplate(
                          selectedTemplate.value.id ||
                              selectedTemplate.value.key,
                          state.value
                      )
                    : await addTemplate(state.value);
                toast.success(
                    i18n.global.t('messages.changesSavedSuccessfully')
                );
                context.emit('close-dialog', true);
            } catch (error: any) {
                toast.error(error.response?.data?.error || error.message, {
                    timeout: false
                });
                context.emit('close-dialog');
            } finally {
                submitted.value = false;
                state.value = {
                    subject: null,
                    body: null,
                    public: false
                };
                savingInProgress.value = false;
                showDialog.value = false;
            }
        };

        return {
            showDialog,
            onCancelClick,
            handleSubmit,
            state,
            v$,
            submitted,
            savingInProgress,
            tinyUrl: process.env?.VUE_APP_TINYMCE_URL,
            selectedTemplate
        };
    }
};
