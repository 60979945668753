<div class="grid">
    <div class="col">
        <div class="flex justify-content-end">
            <AutoComplete
                v-model="searchTerm"
                :suggestions="filteredResults"
                :placeholder="searchTermPlaceHolder"
                @complete="loadData"
                @item-select="onTermSelected"
                optionLabel="name"
                optionValue="id"
            />
        </div>
    </div>
</div>
<p-tree
    :value="categories"
    selectionMode="multiple"
    @nodeExpand="onNodeExpand"
    @nodeSelect="onNodeSelect"
    @nodeUnselect="onNodeUnselect"
    v-model:selectionKeys="selectedCategories"
    :metaKeySelection="false"
    class="text-sm"
    :expandedKeys="expandedKeys"
></p-tree>
