import {computed, ref, toRefs} from 'vue';
import InputText from 'primevue/inputtext';
import InputNumber from 'primevue/inputnumber';
import Button from 'primevue/button';
import Checkbox from 'primevue/checkbox';
import Calendar from 'primevue/calendar';
import Dropdown from 'primevue/dropdown';
import Fieldset from 'primevue/fieldset';
import AutoComplete from 'primevue/autocomplete';
import InputSwitch from 'primevue/inputswitch';
import Textarea from 'primevue/textarea';
import {useVuelidate} from '@vuelidate/core';
import {helpers, required, requiredIf} from '@vuelidate/validators';
import {getAll} from '@/services/metadata';
import {getAllCandidatesForTicket as getAllOrders} from '@/services/sales-orders';
import {getAll as getAllInvoices} from '@/services/sales-invoices';
import {useToast} from 'vue-toastification';
import {getAllCustomers} from '@/services/parties';
import {FilterMatchMode} from 'primevue/api';
import {i18n} from '@/utils/i18n';

export default {
    emits: [
        'back-button-clicked',
        'next-button-clicked',
        'add-wizard-form-dirty'
    ],
    props: {
        ticketDetails: Object
    },
    components: {
        InputText,
        'p-button': Button,
        'p-checkbox': Checkbox,
        'p-calendar': Calendar,
        'p-dropdown': Dropdown,
        'p-fieldset': Fieldset,
        'p-textarea': Textarea,
        InputSwitch,
        AutoComplete,
        InputNumber
    },
    setup(props: any, context: any) {
        const {ticketDetails} = toRefs(props);
        const submitted = ref(false);
        const toast = useToast();
        const warehouseOptions = ref([]);
        const filteredCustomers = ref();
        const filteredOrders = ref();

        const isReturnTicket = computed(
            () => ticketDetails.value?.isReturnTicket || false
        );

        getAll(['warehouse'], false, ['id', 'name'])
            .then((data) => {
                if (data.data?.warehouse) {
                    warehouseOptions.value = parseIntoDropdownList(
                        data.data.warehouse
                    );
                    state.value.warehouseId =
                        data.data.warehouse.find(
                            (wh: any) =>
                                wh.name.includes('Obenhaupt') ||
                                wh.name.includes('Service Center')
                        )?.id || null;
                }
            })
            .catch((error) => {
                toast.error(error.message);
            });

        const searchCustomers = (event: any) => {
            getAllCustomers({
                first: 0,
                rows: 20,
                columns: [
                    'id',
                    'firstName',
                    'lastName',
                    'company',
                    'customerNumber'
                ],
                filters: {
                    firstName: {
                        value: event?.query || '',
                        matchMode: FilterMatchMode.CONTAINS
                    },
                    lastName: {
                        value: event?.query || '',
                        matchMode: FilterMatchMode.CONTAINS
                    },
                    company: {
                        value: event?.query || '',
                        matchMode: FilterMatchMode.CONTAINS
                    },
                    customerNumber: {
                        value: event?.query || '',
                        matchMode: FilterMatchMode.CONTAINS
                    }
                }
            })
                .then((data) => {
                    if (data.data?.items) {
                        filteredCustomers.value = data.data.items.map(
                            (item: {
                                id: string;
                                firstName: string;
                                customerNumber: string;
                                lastName: string;
                                company: string;
                            }) => {
                                return {
                                    label:
                                        '[' +
                                        item.customerNumber +
                                        '] ' +
                                        (
                                            item.company ||
                                            item.firstName ||
                                            '' + ' ' + item.lastName ||
                                            ''
                                        ).trim(),
                                    value: item.id
                                };
                            }
                        );
                    }
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        };

        const searchOrders = (event: any) => {
            getAllOrders({
                first: 0,
                rows: 10,
                columns: ['id', 'orderNumber', 'customerId'],
                filters: {
                    orderNumber: {
                        value: event?.query || '',
                        matchMode: FilterMatchMode.CONTAINS
                    },
                    'customer.customer': {
                        value: true,
                        matchMode: FilterMatchMode.EQUALS
                    },
                    'customer.firstName': {
                        value: event?.query || '',
                        matchMode: FilterMatchMode.CONTAINS
                    },
                    'customer.lastName': {
                        value: event?.query || '',
                        matchMode: FilterMatchMode.CONTAINS
                    },
                    'customer.company': {
                        value: event?.query || '',
                        matchMode: FilterMatchMode.CONTAINS
                    },
                    'customer.customerNumber': {
                        value: event?.query || '',
                        matchMode: FilterMatchMode.CONTAINS
                    }
                }
            })
                .then((data) => {
                    let found: any[] = [];
                    if (data.data?.items) {
                        found = data.data.items.map(
                            (item: {
                                id: string;
                                orderNumber: string;
                                customer: any;
                            }) => {
                                return {
                                    item,
                                    value: item.id,
                                    label: item.orderNumber
                                };
                            }
                        );
                    }
                    if (found.length < 10) {
                        getAllInvoices({
                            first: 0,
                            rows: 10,
                            columns: [
                                'id',
                                'invoiceNumber',
                                'salesOrderId',
                                'customerId'
                            ],
                            filters: {
                                invoiceNumber: {
                                    value: event?.query || '',
                                    matchMode: FilterMatchMode.CONTAINS
                                },
                                'customer.customer': {
                                    value: true,
                                    matchMode: FilterMatchMode.EQUALS
                                },
                                'salesOrders.id': {
                                    value: 'foo',
                                    matchMode: 'notnull'
                                },
                                'customer.firstName': {
                                    value: event?.query || '',
                                    matchMode: FilterMatchMode.CONTAINS
                                },
                                'customer.lastName': {
                                    value: event?.query || '',
                                    matchMode: FilterMatchMode.CONTAINS
                                },
                                'customer.company': {
                                    value: event?.query || '',
                                    matchMode: FilterMatchMode.CONTAINS
                                },
                                'customer.customerNumber': {
                                    value: event?.query || '',
                                    matchMode: FilterMatchMode.CONTAINS
                                }
                            },
                            filterConjunction: 'or'
                        })
                            .then((invoiceData) => {
                                if (invoiceData.data?.items) {
                                    found = found.concat(
                                        invoiceData.data.items.map(
                                            (item: {
                                                invoiceNumber: string;
                                                salesOrder: {
                                                    id: string;
                                                    orderNumber: string;
                                                    customer: any;
                                                };
                                            }) => {
                                                return {
                                                    invoice: item,
                                                    item: item.salesOrder,
                                                    value: item.salesOrder.id,
                                                    label: item.salesOrder
                                                        .orderNumber
                                                };
                                            }
                                        )
                                    );
                                }

                                filteredOrders.value = found.filter(
                                    (value, index, self) =>
                                        index ===
                                        self.findIndex(
                                            (t) =>
                                                t.place === value.place &&
                                                t.value === value.value
                                        )
                                );
                            })
                            .catch((invoiceError) => {
                                toast.error(invoiceError.message);
                            });
                    } else {
                        filteredOrders.value = found.filter(
                            (value, index, self) =>
                                index ===
                                self.findIndex(
                                    (t) =>
                                        t.place === value.place &&
                                        t.value === value.value
                                )
                        );
                    }
                })
                .catch((error) => {
                    toast.error(error.message);
                });
        };

        const formInitialState: any = {
            order:
                ticketDetails.value?.ticketSalesOrder ||
                ticketDetails.value?.ticketSalesInvoice?.salesOrder
                    ? {
                          item:
                              ticketDetails.value?.ticketSalesOrder ||
                              ticketDetails.value?.ticketSalesInvoice
                                  ?.salesOrder,
                          value:
                              ticketDetails.value?.ticketSalesOrder?.id ||
                              ticketDetails.value?.ticketSalesInvoice
                                  ?.salesOrder?.id,
                          label:
                              ticketDetails.value?.ticketSalesOrder
                                  ?.orderNumber ||
                              ticketDetails.value?.ticketSalesInvoice
                                  ?.salesOrder?.orderNumber
                      }
                    : null,
            orderRelated: !!(
                ticketDetails.value?.ticketSalesOrder ||
                ticketDetails.value?.ticketSalesInvoice?.salesOrder
            ),
            customer: null,
            warehouseId: null,
            customerReference: null,
            serviceReceipt: false,
            internalErrorDescription:
                ticketDetails.value?.customAttributes &&
                ticketDetails.value?.customAttributes[
                    'service_internal_error_description'
                ]
                    ? ticketDetails.value?.customAttributes[
                          'service_internal_error_description'
                      ]
                    : null,
            customerErrorDescription:
                ticketDetails.value?.customAttributes &&
                ticketDetails.value?.customAttributes[
                    'service_customer_error_description'
                ]
                    ? ticketDetails.value?.customAttributes[
                          'service_customer_error_description'
                      ]
                    : null,
            isChargeable: false,
            deliveryNoteDate: new Date()
        };

        const state = ref(Object.assign({}, formInitialState));

        const rules = {
            order: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    requiredIf(() => {
                        return !!state.value.orderRelated;
                    })
                )
            },
            orderRelated: {},
            customer: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    requiredIf(() => {
                        return !state.value.orderRelated;
                    })
                )
            },
            warehouseId: {
                required: helpers.withMessage(
                    i18n.global.t('messages.valueIsRequired'),
                    required
                )
            },
            customerReference: {},
            serviceReceipt: {},
            internalErrorDescription: {},
            customerErrorDescription: {},
            isChargeable: {},
            deliveryNoteDate: {required}
        };

        const v$ = useVuelidate(rules, state);

        const handleSubmit = (isFormValid: boolean) => {
            submitted.value = true;
            if (!isFormValid) {
                return;
            }

            const {
                customerErrorDescription,
                orderRelated,
                customerReference,
                deliveryNoteDate,
                internalErrorDescription,
                isChargeable,
                serviceReceipt,
                warehouseId
            } = state.value;

            context.emit('next-button-clicked', {
                stepIndex: 1,
                formValues: {
                    customerErrorDescription,
                    orderRelated,
                    customerReference,
                    deliveryNoteDate,
                    internalErrorDescription,
                    isChargeable,
                    serviceReceipt,
                    warehouseId,
                    orderNumber: state.value.order?.item?.orderNumber || null,
                    partyId: state.value.customer?.value || null
                }
            });
        };

        const onBackButtonClicked = (event: any) => {
            event.preventDefault();
            context.emit('back-button-clicked', 0);
        };

        const parseIntoDropdownList = (data: any) => {
            return data.map((item: {id: string; name: string}) => {
                return {
                    label: item.name,
                    value: item.id
                };
            });
        };

        const setDropdownValue = (fieldName: string, event: any) => {
            if (!event.value) {
                return;
            }

            const temp: {[k: string]: string} = {};
            temp[fieldName] = event.value;
            Object.assign(state.value, temp);
        };

        return {
            state,
            v$,
            handleSubmit,
            submitted,
            setDropdownValue,
            isReturnTicket,
            ticketDetails,
            warehouseOptions,
            filteredCustomers,
            filteredOrders,
            searchCustomers,
            searchOrders,
            onBackButtonClicked
        };
    }
};
