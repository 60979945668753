<loading v-model:active="savingInProgress" />
<PrimeDialog
    :header="dialogHeaderLabel"
    v-model:visible="showDialog"
    :closable="false"
    :breakpoints="{'960px': '75vw', '640px': '90vw'}"
    :style="{width: '75vw'}"
    modal
>
    <form @submit.prevent="handleSubmit(!v$.$invalid)">
        <div class="grid font-bold">
            <div class="col">{{$t('labels.article')}}</div>
            <div class="col-3">{{$t('labels.shipment.shipmentQuantity')}}</div>
            <div class="col">Quelllagerplatz</div>
            <div class="col">Ziellagerplatz</div>
        </div>
        <template v-for="(item, index) in state.shipmentItems" :key="index">
            <div class="grid mt-3 text-sm">
                <div class="col">
                    <AutoComplete
                        v-model="item.articleNumber"
                        :placeholder="$t('labels.article')"
                        :suggestions="filteredProducts"
                        :class="{'p-invalid':submitted && v$.shipmentItems.$each.$response.$data[index].articleNumber.$invalid}"
                        @complete="searchProducts($event)"
                        @item-select="dropdownSelect(index, $event)"
                        dropdown
                        optionLabel="label"
                        optionValue="value"
                        forceSelection
                    />
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.shipmentItems.$each.$response.$errors[index].articleNumber"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                    <small class="font-bold ml-1"
                        >{{item.orderItem?.title || item.article?.name}}</small
                    >
                </div>
                <div class="col-3">
                    <InputNumber
                        :locale="locale"
                        :class="{'p-invalid':submitted && v$.shipmentItems.$each.$response.$data[index].shipmentQuantity.$invalid}"
                        autocomplete="off"
                        v-model="item.shipmentQuantity"
                        :disabled="item.serialNumberRequired"
                    />
                    {{item.unitName}}
                    <span
                        v-if="item.serialNumberRequired && typeof item.sourceStoragePlaceId === 'object' && item.sourceStoragePlaceId !== null"
                        class="pi pi-plus-circle text-green-600"
                        @click="openAddSerialNumberDialog(index, item)"
                    ></span>
                    <ul v-if="item.selectedSerialNumbers.length > 0">
                        <li
                            class="text-sm"
                            v-for="element in item.selectedSerialNumbers"
                        >
                            {{ element}}
                            <span
                                class="pi pi-times-circle text-red-600 mt-1"
                                @click="removeSerialNumberItem(index, element, $event)"
                            ></span>
                        </li>
                    </ul>

                    <small
                        v-if="submitted"
                        style="display: block"
                        class="p-error"
                        v-for="error in v$.shipmentItems.$each.$response.$errors[index].shipmentQuantity"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                    <small
                        v-if="submitted"
                        style="display: block"
                        v-for="error in v$.shipmentItems.$each.$response.$errors[index].selectedSerialNumbers"
                        class="p-error"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
                <div class="col">
                    <div>
                        <p-dropdown
                            v-if="isCustomerCompensationShipment"
                            v-model="customerCompensationSourceWarehouseId"
                            :options="warehouses || []"
                            optionLabel="name"
                            optionValue="id"
                            @change="item.sourceStoragePlaceId = null"
                            class="mb-3 w-full"
                        >
                        </p-dropdown>
                        <AutoComplete
                            v-model="item.sourceStoragePlaceId"
                            :suggestions="filteredSourceStoragePlaces"
                            :class="{'p-invalid':submitted && v$.shipmentItems.$each.$response.$data[index].sourceStoragePlaceId.$invalid}"
                            @complete="searchSourceStoragePlaces($event)"
                            forceSelection
                            class="w-full"
                            inputClass="w-full"
                            optionLabel="label"
                            optionValue="value"
                        />
                        <small
                            v-if="submitted"
                            style="display: block"
                            class="p-error"
                            v-for="error in v$.shipmentItems.$each.$response.$errors[index].sourceStoragePlaceId"
                            :key="error"
                        >
                            {{ error.$message }}
                        </small>
                    </div>
                </div>
                <div class="col">
                    <AutoComplete
                        v-model="item.destinationStoragePlaceId"
                        :suggestions="filteredDestinationStoragePlaces"
                        :class="{'p-invalid':submitted && v$.shipmentItems.$each.$response.$data[index].destinationStoragePlaceId.$invalid}"
                        @complete="searchDestinationStoragePlaces($event)"
                        forceSelection
                        class="w-full"
                        inputClass="w-full"
                        optionLabel="label"
                        optionValue="value"
                    />
                    <small
                        v-if="submitted"
                        style="display: block"
                        class="p-error"
                        v-for="error in v$.shipmentItems.$each.$response.$errors[index].destinationStoragePlaceId"
                        :key="error"
                    >
                        {{ error.$message }}
                    </small>
                </div>
            </div>
            <div class="grid">
                <div class="col">
                    <small style="display: block"
                        >{{stripTagsAndTruncate(item.orderItem?.description ||
                        item.article?.description)}}</small
                    >
                </div>
            </div>
        </template>
        <div class="w-full flex justify-content-between mt-4">
            <p-button
                severity="danger"
                v-text="$t('buttons.cancel')"
                @click="onCancelButtonClicked"
            >
            </p-button>

            <p-button
                severity="success"
                v-text="$t('buttons.confirm')"
                type="submit"
            >
            </p-button>
        </div>
    </form>
</PrimeDialog>
<AddSerialNumber
    :display-add-serial-number-dialog="showAddSerialNumberDialog"
    :row-index="clickedRowIndex"
    :warehouse-stock-movement-filters="addSerialNumberDialogFilters"
    :article-id="clickedRow?.article?.id"
    @close-dialog="closeDialogListener"
>
</AddSerialNumber>
